import { useMutation } from 'react-query';

import * as orderAction from 'services/order.service';

export const useOrderActions = () => {
  const { mutateAsync: process } = useMutation(orderAction.process);

  return {
    process,
  };
};
