import { Card, Typography, Form, message, Button } from 'antd';
import { useMutation } from 'react-query';
import CategoryCreateForm from 'components/common/CateogyCreateForm';
import { createCategory } from 'services/category.service';
import { useCallback } from 'react';
import { uploadFile } from 'services/storage.service';

import styles from '../../common/CateogyCreateForm/index.module.scss';

const { Text } = Typography;

export interface CategoryCreate extends SEO {
  name: string,
  categoryDescription: string
  image: any,
  link: string,
  seoTranslation: {
    translation: Record<string, Record<string, string>>[],
  }
  root: {
    translation: Record<string, Record<string, string>>[]
  }
  regions: any
}

const Category = () => {
  const { mutateAsync: mutate } = useMutation(createCategory);
  const { mutateAsync: upload } = useMutation(uploadFile);
  const [form] = Form.useForm();

  const onFinish = useCallback(({
    name, description, title, keywords, categoryDescription, root, image, link, regions, seoTranslation, ...other
  }: CategoryCreate) => {
    const category = {
      seo: {
        translation: seoTranslation.translation,
        description,
        keywords,
        title,
      },
      description: categoryDescription,
      translation: root.translation,
      regions,
      name,
      link,
    };

    const formData = new FormData();
    formData.append('image', image.file.originFileObj);
    formData.append('path', `category/${link}`);

    upload(formData)
      .then((result) => {
        mutate({ ...category, image: result.data }).then(() => {
          form.resetFields();
          message.success('Category successfully added');
        });
      }).catch((e) => message.error(e.message));
  }, []);

  return (
    <div>
      <Text type='secondary'>Category create</Text>
      <Card>
        <CategoryCreateForm form={form} onFinish={onFinish} />
        <Button className={styles.save} onClick={() => form.submit()} type='primary'>Create category</Button>
      </Card>
    </div>
  );
};

export default Category;
