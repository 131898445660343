import dayjs from 'dayjs';
import { List, Typography, Pagination, Badge } from 'antd';
import { useCallback, useState } from 'react';
import { usePurchases } from 'hooks/api';

import styles from './index.module.scss';

const { Title } = Typography;

const status = {
  DONE: '#42c430',
  PENDING: '#fa541c',
  CREATED: '#13c2c2',
};

const dateInfo = [
  { field: 'createdAt', title: 'DATE PAID:', dateFormat: 'MMM D, YYYY' },
  { field: 'updatedAt', title: 'LAST CHANGES:', dateFormat: 'MMM D, YYYY' },
];

const limit = 10;

const PurchaseList = () => {
  const [page, setPage] = useState(1);
  const { data } = usePurchases({ skip: (page - 1) * limit, limit });

  const renderItem = useCallback((item: Purchases) => (
    <Badge.Ribbon text={item?.status} color={status[item?.status]}>
      <List.Item className={styles.list}>
        <div>
          <div
            className={styles.referenceCode}
            style={{ backgroundColor: status[item?.status] }}
          >
            {item.referenceCode}
          </div>
          <div className={styles.price}>
            $
            {item.price / 100}
            {' '}
            <span className={styles.amount}>
              (
              {item.amount}
              )
            </span>
          </div>
          <div>
            SKU:
            {' '}
            {item.sku}
          </div>
        </div>
        <div className={styles.rightContainer}>
          {
            dateInfo.map((i, idx) => (
              <div key={idx.toString()}>
                {i.title}
                {' '}
                <span className={styles.date}>{dayjs(item[i.field]).format(i.dateFormat)}</span>
              </div>
            ))
          }
        </div>
      </List.Item>
    </Badge.Ribbon>
  ), []);

  return (
    <div>
      <Title level={2}>Purchasing</Title>
      <List
        itemLayout='vertical'
        size='large'
        dataSource={data?.data || []}
        renderItem={renderItem}
      />
      <Pagination
        className={styles.pagination}
        defaultCurrent={1}
        total={data?.total || 1}
        onChange={(p: number) => setPage(p)}
      />
    </div>
  );
};

export default PurchaseList;
