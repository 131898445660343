import { Col, Form, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { CardType, CardTypeMap } from 'config/constants';

export interface CodeItemProps {
  field: any;
}

const draftType = CardTypeMap[CardType.CardNumber];

export const CodeItem = ({ field }: CodeItemProps) => {
  const [type, setType] = useState(draftType);
  return (
    <Row gutter={[16, 16]} justify='space-between'>
      <Col span={12}>
        <Form.Item label='Type' name={[field.name, 'type']} initialValue={draftType}>
          <Select
            placeholder='Type'
            onChange={(v) => setType(v)}
            options={Object.values(CardTypeMap).map((v) => ({ label: v, value: v }))}
          />
        </Form.Item>
      </Col>
      { type
        && (
        <Col span={12}>
          <Form.Item label={type} name={[field.name, 'code']}>
            <Input
              placeholder={`${type} ...`}
            />
          </Form.Item>
        </Col>
        )}
    </Row>
  );
};
