import { Card } from 'antd';
import { Map } from 'components/common/Map';
import { GOOGLE_API_KEY } from 'config/constants';
import { KeyValue } from 'components/common/KeyValue';

const draft = {
  latitude: 0,
  longitude: 0,
  city: 'empty',
  region: 'empty',
  country: 'empty',
} as Location;

export interface LocationProps {
  location?: Location;
  className?: string;
}
export const Location = ({ className, location = draft }: LocationProps) => (
  <Card
    title='Location'
    className={className}
  >
    <KeyValue title='Country' value={location.country} />
    <KeyValue title='City' value={location.city} />
    <KeyValue title='Region' value={location.region} />
    <Map lat={location.latitude} long={location.longitude} apiKey={GOOGLE_API_KEY} />
  </Card>
);
