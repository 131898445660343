import { usePagination } from 'hooks/common';
import { useUsersList } from 'hooks/api';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { ColumnsType } from 'antd/es/table';

import styles from './index.module.scss';

const responsive: Breakpoint[] = ['sm', 'xl', 'lg', 'xxl', 'md'];

interface DataType {
  key: React.Key;
  email: string;
  referrer: number;
  data: string;
}

const limit = 10;
const columns: ColumnsType<DataType> = [
  {
    title: 'email',
    render: (record) => record?.email,
    responsive,
  },
  {
    title: 'referrer',
    render: (record) => record?.referrer,
    responsive,
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => (<span>{dayjs(createdAt).format('MMMM D, YYYY h:mm A')}</span>),
    responsive: [...responsive, 'xs'],
  },
];

export const UserList = () => {
  const { page, setPage } = usePagination(1);
  const { data, isLoading } = useUsersList({ skip: (page - 1) * limit, limit });
  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        dataSource={data?.data || []}
        pagination={false}
        loading={isLoading}
      />
      <Pagination
        className={styles.pagination}
        defaultCurrent={page}
        total={data?.total || 1}
        onChange={(p: number) => setPage(p)}
        current={page}
      />
    </div>
  );
};
