import { Card, Row, Typography, Tag, Col } from 'antd';
import { useCallback, useState } from 'react';
import { useCategoryList } from 'hooks/api';
import { useProductForm } from 'hooks/common';
import { ProductForm } from 'components/common/ProductForm';

import styles from './index.module.scss';

const { Text } = Typography;

interface CreateProductProps {
  product: Product.Provider,
  next: (p: { action: string, value: any }) => void,
}

const CreateProduct = ({ product: incomeProduct, next }: CreateProductProps) => {
  const [product] = useState<Product.Provider>({ ...incomeProduct, region: incomeProduct.regions[0] });
  const { data: categories = [] } = useCategoryList();
  const { productFormation } = useProductForm(product);

  const onFinish = useCallback((form: Record<string, any>, file: any) => {
    const result = productFormation(form);
    return next({ action: 'createProduct', value: { ...result, file } });
  }, [product, next]);

  return (
    <>
      <Text type='secondary' className={styles.header}> Fill in the fields and click</Text>
      <Card title={product.categories[0].name} className={styles.productCard}>

        <Row className={styles.valueInfo}>
          <Col>
            Min price:
            {' '}
            <Tag color='cyan'>{product?.min_price}</Tag>
          </Col>
          <Col>
            Max price:
            {' '}
            <Tag color='cyan'>{product?.max_price}</Tag>
          </Col>
        </Row>
        <ProductForm
          categories={categories}
          onFinish={onFinish}
          type='submit'
        />
      </Card>
    </>
  );
};

export default CreateProduct;
