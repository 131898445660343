import { CloseOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { Select } from 'antd';

import classNames from 'classnames';
import styles from './index.module.scss';

export interface FilterProps {
  onChange: (filter: string, value: string | null) => void;
  filter: string;
  options: { label: string, value: string }[]
  placeholder: string;
  className?: string;
}

export const Filter = ({ options, onChange, filter, placeholder, className }: FilterProps) => {
  const [selected, toggle] = useState<boolean>();
  const [value, setValue] = useState<any>();
  const handleOnSelect = useCallback((v: string) => {
    onChange(filter, v);
    setValue(v);
    toggle(true);
  }, []);

  const handleClose = useCallback(() => {
    toggle(false);
    setValue(null);
    onChange(filter, null);
  }, []);

  return (
    <div className={classNames(styles.filterWrapper, className)}>
      <CloseOutlined className={classNames(styles.close, { [styles.visible]: selected })} onClick={handleClose} />
      <Select
        onChange={handleOnSelect}
        options={options}
        className={styles.filter}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};
