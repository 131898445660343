import { Card, Col, Table } from 'antd';

export interface AccountsProps {
  users: User[]
}

const COLUMNS = [
  {
    title: 'id',
    dataIndex: '_id',
  },
  {
    title: 'email',
    dataIndex: 'email',
  },
  {
    title: 'ip',
    dataIndex: 'ip',
  },
  {
    title: 'isFraud',
    render: (record: User) => (!!record?.isFraud)?.toString(),
  },
];

export const Accounts = ({ users }: AccountsProps) => (
  <Col xxl={12}>
    <Card
      title='Accounts'
    >
      <Table
        columns={COLUMNS}
        dataSource={users}
        pagination={false}
      />
    </Card>
  </Col>
);
