import { ChangeEvent, FormEvent, useState } from 'react';
import { Input, Typography, Row, Button } from 'antd';
import { useAuth } from 'hooks/api';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.css';

const { Title } = Typography;

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    login({ email, password })
      .then((user: User) => navigate('/'))
      .catch((e) => console.log('error: ', e));
  };

  return (
    <div className={styles.container}>
      <form id='loginForm' onSubmit={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Title>Sign In</Title>
          <Input
            placeholder='Email'
            onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          />
          <Input.Password
            placeholder='Password'
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
          />
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Row>
      </form>
    </div>
  );
};

export default SignIn;
