import { useMutation } from 'react-query';
import { signIn } from 'services/auth.service';

export const useAuth = () => {
  const { mutateAsync: login } = useMutation(signIn);

  return {
    login,
  };
};
