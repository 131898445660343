import { List, Typography, Avatar, Tag } from 'antd';
import { getCategoryQueryKey, useCategoryList } from 'hooks/api';
import { EditFilled, EyeFilled } from '@ant-design/icons';
import { useCallback } from 'react';
import classNames from 'classnames';

import { changeCategoryVisible } from 'services/category.service';
import { useMutation, useQueryClient } from 'react-query';

import styles from './index.module.scss';
import APP_ROUTES from '../../../config/routes';

const { Text } = Typography;

const CategoryList = () => {
  // @ts-ignore
  const { data, isLoading } = useCategoryList();
  const { mutateAsync: changeVisible } = useMutation(changeCategoryVisible);
  const client = useQueryClient();

  const handleChangeVisible = useCallback((category, visible) => {
    changeVisible({ category, visible }).then(() => {
      client.setQueryData([getCategoryQueryKey], (categories: Category[]) => categories.map((c) => {
        if (c.name === category) c.visible = visible;
        return c;
      }));
    }, []);
  }, []);

  const renderItem = useCallback((item: Category, idx) => {
    const descriptionRender = (
      <div className={styles.description}>
        <div>
          <Tag key={idx.toString()} className={styles.tag}>
            {item.name}
          </Tag>
        </div>
        <EyeFilled
          onClick={() => handleChangeVisible(item.name, !item.visible)}
          className={classNames(styles.icon, { [styles.visible]: item.visible })}
        />
        <a
          aria-label='edit'
          className={styles.edit}
          href={APP_ROUTES.CATEGORY_EDIT.replace(':id', item.name)}
        >
          <EditFilled />
        </a>
      </div>
    );

    return (
      <List.Item className={styles.list} key={idx.toString()}>
        <List.Item.Meta
          avatar={<Avatar src={item.image} />}
          description={descriptionRender}
        />
      </List.Item>
    );
  }, []);

  return (
    <div>
      <Text type='secondary'>Product list</Text>
      <List
        itemLayout='vertical'
        size='large'
        dataSource={data}
        renderItem={renderItem}
        loading={isLoading}
      />
      {/* <Pagination className={styles.pagination} defaultCurrent={1} total={data?.count || 1} /> */}
    </div>
  );
};

export default CategoryList;
