import { Badge, Col, Skeleton } from 'antd';

import styles from './index.module.scss';

export interface MetricItemProps {
  background?: string;
  color?: string;
  title: string;
  value?: number;
  loading: boolean;
}

export const MetricItem = ({ background = '#1677ff', color = '#f5f5f5', title, value, loading }: MetricItemProps) => (
  <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
    <Badge count={value} className={styles.badge} overflowCount={Infinity}>
      <div className={styles.item} style={{ backgroundColor: background, color }}>
        <Skeleton loading={!loading}>
          { title }
        </Skeleton>
      </div>
    </Badge>
  </Col>
);
