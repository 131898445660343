import { useQuery } from 'react-query';
import { getProvideProducts } from 'services/provider.service';
import { AxiosResponse } from 'axios';

export const getProviderProductsQueryKey = 'getProviderProducts';

export const useProviderProduct = (provider: string) => useQuery([getProviderProductsQueryKey, provider], () => getProvideProducts(provider)
  .then((result: AxiosResponse<string[]>) => result.data), {
  refetchOnWindowFocus: false, retry: false,
});
