import { useQuery } from 'react-query';
import { getPurchases } from 'services/purchase.service';
import { AxiosResponse } from 'axios';

export const getPurchasesQueryKey = 'getPurchases';

export const usePurchases = (dataPage) => useQuery([getPurchasesQueryKey, dataPage], () => getPurchases(dataPage)
  .then((result: AxiosResponse<string[]>) => result.data || []), {
  refetchOnWindowFocus: false, retry: false,
});
