import { getRedirect } from 'services/redirect.service';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

export const getRedirectQueryKey = 'getRedirect';

export const useRedirect = (id) => useQuery([getRedirectQueryKey, id], () => getRedirect(id)
  .then((result: AxiosResponse<Redirect>) => result.data), {
  refetchOnWindowFocus: false, retry: false,
});
