import { ReactNode, Key, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu } from 'antd';
import type { MenuProps } from 'antd';
import {
  HomeOutlined,
  WalletOutlined,
  TagsOutlined,
  ShopOutlined,
  LoginOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Routes from 'config/routes';
import { useMe } from 'hooks/api';

import classNames from 'classnames';
import styles from './index.module.scss';

const Layout = ({ children: main }: { children: ReactNode}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  type MenuItem = Required<MenuProps>['items'][number];
  const router = useNavigate();
  const { data, isLoading } = useMe();
  useEffect(() => {
    if (!isLoading && !data) router('/sign-in');
  }, [data, isLoading]);

  const handleToggleMenu = useCallback(() => {
    setCollapsed((p) => !p);
  }, []);

  const getItem = (
    label: ReactNode,
    key?: Key | null,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem => ({
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem);

  const items: MenuItem[] = [
    getItem('Home', '/', <HomeOutlined />),
    getItem('Product', 'sub2', <TagsOutlined />, [
      getItem('Create', Routes.PRODUCT_CREATE),
      getItem('List', Routes.PRODUCT_LIST),
    ]),
    getItem('Purchase', 'sub3', <WalletOutlined />, [
      getItem('Create', Routes.PURCHASE_CREATE),
      getItem('List', Routes.PURCHASE_LIST),
    ]),
    getItem('Category', 'sub4', <ShopOutlined />, [
      getItem('Create', Routes.CATEGORY_CREATE),
      getItem('List', Routes.CATEGORY_LIST),
    ]),
    getItem('Order', 'sub5', <ShopOutlined />, [
      getItem('List', Routes.ORDER_LIST),
    ]),
    getItem('Users', 'sub6', <UserOutlined />, [
      getItem('List', Routes.USER_LIST),
    ]),
    getItem('Redirect', 'sub7', <ShopOutlined />, [
      getItem('Create', Routes.REDIRECT_CREATE),
      getItem('List', Routes.REDIRECT_LIST),
    ]),
    getItem('Login', Routes.SIGN_IN, <LoginOutlined />),
  ];

  const onClick: MenuProps['onClick'] = (e) => router(e.key);

  return (
    <div className={styles.layoutContainer}>
      <div className={classNames(styles.sidebar, { [styles.notSolid]: !collapsed })}>
        <Button type='primary' onClick={handleToggleMenu} className={styles.toggleButton}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Menu
          onClick={onClick}
          mode='inline'
          theme='dark'
          inlineCollapsed={collapsed}
          items={items}
          className={classNames(styles.menu, { [styles.collapsed]: collapsed })}
        />
      </div>
      <div id='main'>
        {main}
      </div>
    </div>
  );
};

export default Layout;
