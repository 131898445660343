import { Button, Card, Col, Form, Input, Row, Space, Upload, Collapse } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

import styles from '../../index.module.scss';
import TranslateFormWrapper from '../../../TranslationForm';

export interface CategoryFormFieldsProps {
  fieldPrefix?: string;
}

const CategoryFormFields = ({ fieldPrefix = '' }: CategoryFormFieldsProps) => {
  const renderHeader = useCallback((remove: (v: number) => void, idx: number) => (
    <div>
      <Row className={styles.regionHeader}>
        <Col>
          {`region ${idx}`}
        </Col>
        <Col>
          <DeleteOutlined onClick={() => remove(idx)} />
        </Col>
      </Row>
    </div>
  ), []);

  return (
    <Row>
      <Col span={12}>
        <Card title='General' className={styles.general}>
          <Form.Item
            label='name'
            name={`${fieldPrefix}name`}
          >
            <Input placeholder='Category name' />
          </Form.Item>

          <Form.Item
            label='description'
            name={`${fieldPrefix}categoryDescription`}
          >
            <Input.TextArea placeholder='Category description' />
          </Form.Item>

          <Form.Item
            label='Link'
            name={`${fieldPrefix}link`}
          >
            <Input placeholder='Category Link' />
          </Form.Item>
          <Form.List name='regions'>
            {(regions, { add, remove }) => (
              <div>
                <Collapse>
                  {regions.map(({ name, key, ...region }, idx) => (
                    <Collapse.Panel key={key} header={renderHeader(remove, idx)}>
                      <Col span={24}>
                        <Form.Item
                          name={[name, 'name']}
                          label='name'
                        >
                          <Input placeholder='name' />
                        </Form.Item>
                        <Form.Item
                          name={[name, 'code']}
                          label='code'
                        >
                          <Input placeholder='code' />
                        </Form.Item>
                        <Form.Item
                          name={[name, 'link']}
                          label='link'
                        >
                          <Input placeholder='link' />
                        </Form.Item>
                      </Col>
                    </Collapse.Panel>
                  ))}
                </Collapse>
                <Button onClick={() => add()} className={styles.regionButton}>Add region</Button>
              </div>
            )}
          </Form.List>

          <TranslateFormWrapper
            field={{ name: `${fieldPrefix}root`, key: `${fieldPrefix}root` }}
            items={[{
              name: 'description',
              item: <Input.TextArea placeholder='description translation' />,
            }]}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card title='SEO'>
          <Form.Item
            label='Meta title'
            name={`${fieldPrefix}title`}
          >
            <Input placeholder='Meta title' />
          </Form.Item>
          <Form.Item
            label='Description'
            name={`${fieldPrefix}description`}
          >
            <Input.TextArea placeholder='Meta description' />
          </Form.Item>
          <Form.Item
            label='Keywords'
            name={`${fieldPrefix}keywords`}
          >
            <Input.TextArea placeholder='Keywords' />
          </Form.Item>
          <TranslateFormWrapper
            field={{ name: `${fieldPrefix}seoTranslation`, key: `${fieldPrefix}root` }}
            items={[
              {
                name: 'seoTitle',
                label: 'Title',
                item: <Input placeholder='title translation' />,
              },
              {
                name: 'seoDescription',
                label: 'Description',
                item: <Input.TextArea placeholder='description translation' />,
              },
              {
                name: 'seoKeywords',
                label: 'Keywords',
                item: <Input.TextArea placeholder='keywords translation' />,
              },
            ]}
          />
        </Card>
      </Col>
      <Form.Item
        name='image'
      >
        <Upload>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </Row>
  );
};

export default CategoryFormFields;
