import { API_URLS } from 'config/constants';
import qs from 'qs';

import httpServices from './http.service';

export const getOrders = (params) => (httpServices.get(API_URLS.ORDER.ALL, {
  params,
  paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
}));
export const getOrderById = (params) => (httpServices.get(`${API_URLS.ORDER.BY_ID}${params.id}`));

export const process = async ({ products, orderId }: { products: [], orderId: string }) => (
  httpServices.post(API_URLS.MANUAL_DELIVERY.PROCESS, { products, orderId })
);
