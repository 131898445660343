import { Button, Form, FormInstance } from 'antd';

import FormFields from './components/CategoryFormFileds';
import styles from './index.module.scss';
import { CategoryCreate } from '../../Category/Create';

export interface CategoryCreateFormProps {
  onFinish: (formValue: CategoryCreate) => void;
  form: FormInstance<any>;
}

const CategoryCreateForm = ({ onFinish, form }: CategoryCreateFormProps) => (
  <Form className={styles.form} onFinish={onFinish} form={form} autoComplete='off'>
    <FormFields />
  </Form>
);

export default CategoryCreateForm;
