import { Button, Collapse, Form, Row } from 'antd';

import { useCallback } from 'react';
import styles from './index.module.scss';
import ValueFormItem from '../ValueFormItem';

export interface ValueFormProps {
  category: Category
}

const ValueForm = ({ category }: ValueFormProps) => {
  const renderCollapseHeader = useCallback((index, remove) => (
    <Row className={styles.valueHeader}>
      <span>{`${index + 1} product value`}</span>
      <Button className={styles.removeButton} onClick={() => remove(index)}>Remove</Button>
    </Row>
  ), []);

  return (
    <Form.List name='values'>
      {(fields, { add, remove }) => (
        <div>
          <Collapse defaultActiveKey={['1']}>
            {fields.map((field, index) => (
              <Collapse.Panel header={renderCollapseHeader(index, remove)} key={index} className={styles.valueRow}>
                <ValueFormItem category={category} field={field} />
              </Collapse.Panel>
            ))}
          </Collapse>
          <Button onClick={(e) => { e.preventDefault(); add(); }} className={styles.addValueButton}>
            Add value
          </Button>
        </div>
      )}
    </Form.List>
  );
};

export default ValueForm;
