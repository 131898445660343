import classNames from 'classnames';
import { Avatar, Button, Card } from 'antd';
import { KeyValue } from 'components/common/KeyValue';
import { useMutation, useQueryClient } from 'react-query';
import { markIsFraud } from 'services/user.service';
import { useCallback } from 'react';
import { getOrdersByIdQueryKey } from 'hooks/api';
import { GoogleIcon } from 'components/icons';

import styles from './index.module.scss';

export interface UserInfoProps {
  order: Order.Item,
  className: string,
}

export const UserInfo = ({ order, className }: UserInfoProps) => {
  const { mutateAsync: markIsFraudMutate, isLoading } = useMutation(markIsFraud);
  const client = useQueryClient();

  const handleMarkIsFraud = useCallback(() => {
    markIsFraudMutate(order.user._id).then(() => {
      client.setQueryData([getOrdersByIdQueryKey, order._id], (o: Order.Item) => {
        o.user.isFraud = true;
        return o;
      });
    });
  }, []);

  return (
    <Card
      title='User info'
      className={classNames(styles.card, className)}
      actions={[
        <Button type='primary' loading={isLoading} onClick={handleMarkIsFraud}>Mark is fraud</Button>,
      ]}
      aria-colspan={8}
    >
      <div className={styles.avatar}>
        <Avatar>{order.user.email.substring(0, 1)}</Avatar>
        <div className={styles.avatarTitle}>{order.user.email}</div>
        { order.user.isGoogleAccount && <GoogleIcon className={styles.googleIcon} /> }
      </div>
      <KeyValue title='Ip' value={order.user.ip} />
      <KeyValue title='Id' value={order.user._id} />
      <KeyValue title='Currency' value={order.user.currency} />
      <KeyValue title='Language' value={order.user.lang} />
      <KeyValue title='Terms' value={order?.user?.terms?.toString()} />
      <KeyValue title='Activate' value={order.user?.activate?.toString()} />
      <KeyValue title='Is fraud' value={order.user?.isFraud?.toString()} />
      <KeyValue title='VPN detected' value={order.user?.vpnDetected?.toString()} />
      <KeyValue title='Multiple account detected' value={order.user?.multipleAccount?.toString()} />
      <KeyValue title='Referrer' value={order.user.referrer} />
    </Card>
  );
};
