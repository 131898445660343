import { RedirectForm } from 'components/common/RedirectForm';
import { updateRedirect } from 'services/redirect.service';
import { Button, Form, message, Typography } from 'antd';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useRedirect } from 'hooks/api';

export const RedirectEdit = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { data: redirect } = useRedirect(id);
  const { mutateAsync: update } = useMutation(updateRedirect);
  const handleAddRedirect = useCallback((payload) => {
    update({ payload, id }).then(() => message.success('Redirect successfully updated!'));
  }, []);

  useEffect(() => {
    if (redirect) {
      form.setFieldsValue({
        from: redirect.from,
        to: redirect.to,
      });
    }
  }, [redirect]);

  return (
    <div>
      <Typography.Text type='secondary'>Redirect create</Typography.Text>
      <RedirectForm form={form} onFinish={handleAddRedirect} />
      <Button onClick={form.submit}>Save</Button>
    </div>
  );
};
