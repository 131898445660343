import { EditFilled } from '@ant-design/icons';
import { useRedirects } from 'hooks/api';
import { Typography, Table } from 'antd';
import { Link } from 'react-router-dom';

const COLUMNS = [
  {
    dataIndex: 'from',
    title: 'from',
  },
  {
    dataIndex: 'to',
    title: 'to',
  },
  {
    render: (record: Redirect) => <Link to={`/redirect/${record._id}/edit`}><EditFilled /></Link>,
  },
];

export const RedirectList = () => {
  const { data = [], isLoading } = useRedirects();

  return (
    <div>
      <Typography.Text type='secondary'>Redirect list</Typography.Text>
      <Table
        dataSource={data}
        columns={COLUMNS}
        loading={isLoading}
      />
    </div>
  );
};
