import classNames from 'classnames';
import { Avatar, Button, Card } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { KeyValue } from 'components/common/KeyValue';
import { Profit } from 'components/common/Profit';

import styles from './index.module.scss';

export interface OrderInfoProps {
  order: Order.Item
  toggleModal: () => void;
  className: string;
}

export const OrderInfo = ({ order, toggleModal, className }: OrderInfoProps) => {
  const renderItem = useCallback((item: Order.OrderItem, idx) => (
    <div key={idx.toString()} className={styles.item}>
      <div className={styles.avatar}>
        <Avatar src={item.product.image} />
        <div className={styles.avatarTitle}>
          {item.product.name}
          &nbsp;
          $
          {item.displayName || item.originPrice / 100}
        </div>
        <Profit value={item.profit / 100} />
      </div>
      <KeyValue title='Amount' value={`x${item.amount}`} />
      <KeyValue title='Price' value={`$${item.price / 100}`} />
      {item.delivered ? '(DELIVERED)' : null}
    </div>
  ), []);
  return (
    <Card
      title='Order info'
      className={classNames(styles.card, className)}
      actions={[
        <Button type='primary' onClick={toggleModal}>Process order</Button>,
        <Button disabled type='primary'>Cancel order</Button>,
      ]}
    >
      <KeyValue title='Id' value={order._id} />
      <KeyValue title='Status' value={order.status} />
      <KeyValue title='Ip' value={order.ip} />
      <KeyValue title='Created date' value={dayjs(order.createdAt).format('MMMM D, YYYY h:mm A')} />
      <KeyValue title='Profit' className={styles.profit} value={`$${(order.profit / 100).toString()}`} />
      <Card title='Items info'>
        <div className={styles.items}>
          {order.items.map(renderItem)}
        </div>
      </Card>
    </Card>
  );
};
