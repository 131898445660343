export default {
  SIGN_IN: '/sign-in',
  HOME: '/',
  PRODUCT: '/product',
  PRODUCT_CREATE: '/product/create',
  PRODUCT_LIST: '/product/list',
  PRODUCT_EDIT: '/product/:id/edit',
  PURCHASE: '/purchase',
  PURCHASE_CREATE: '/purchase/create',
  PURCHASE_LIST: '/purchase/list',
  ORDER_LIST: '/order/list',
  USER_LIST: '/user/list',
  ORDER_DETAILS: '/order/details/:id',
  CATEGORY_CREATE: '/category/create',
  CATEGORY_LIST: '/category/list',
  CATEGORY_EDIT: '/category/:id/edit',
  REDIRECT_LIST: '/redirect/list',
  REDIRECT_EDIT: '/redirect/:id/edit',
  REDIRECT_CREATE: '/redirect/create',
};
