import { Row, Col, Select, Typography } from 'antd';
import { useProviders } from 'hooks/api';
import { useState } from 'react';

import Index from './components/PurchaseProductList';

const { Text } = Typography;
const { Option } = Select;

const PurchaseCreate = () => {
  const { data: providers } = useProviders();
  const [selectProvider, setSelectProvider] = useState('');

  const onChange = (value: string) => setSelectProvider(value);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Text type='secondary'>Select Provider</Text>
      </Col>
      <Select style={{ width: '100%' }} onChange={onChange}>
        {providers?.map((p: string) => (
          <Option key={p}>{p}</Option>
        ))}
      </Select>
      <Index provider={selectProvider} />
    </Row>
  );
};
export default PurchaseCreate;
