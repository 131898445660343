import { SetStateAction, useState } from 'react';

import {
  Select, Typography, Col, Button, Checkbox,
} from 'antd';
import { NextStep } from '../../../interfaces';

const { Text } = Typography;
const { Option } = Select;

interface ProviderProps {
  next: NextStep
  providers: string[];
}

const Index = ({ providers, next }: ProviderProps) => {
  const [provider, setProvider] = useState('');
  const [check, setCheck] = useState(false);
  const onChange = (value: string) => setProvider(value);

  return (
    <>
      <Col span={24}>
        <Text type='secondary'>Select the desired provider, and then click on NEXT</Text>
      </Col>
      <Checkbox onChange={() => setCheck((s) => !s)}>Manual creation</Checkbox>
      <Select style={{ width: '100%' }} onChange={onChange}>
        {providers?.map((p) => (
          <Option key={p.toString()}>{p}</Option>
        ))}
      </Select>
      <Button type='primary' onClick={() => next({ action: 'provider', value: { provider, check } })}>
        Next
      </Button>
    </>
  );
};

export default Index;
