import { API_URLS } from 'config/constants';
import httpServices from './http.service';

export const createProduct = (data: Product.Item) => (
  httpServices.post(API_URLS.PRODUCT.CREATE, data)
);

export const getProduct = (id: string) => (
  httpServices.get(API_URLS.PRODUCT.GET.replace('[id]', id))
);

export const editProduct = ({ id, product }: { id: string, product: Product.Item }) => (
  httpServices.patch(API_URLS.PRODUCT.EDIT.replace('[id]', id), product)
);
export const getProducts = () => (httpServices.get(API_URLS.PRODUCT.ALL));
