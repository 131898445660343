import { getMe } from 'services/auth.service';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

export const getMeQueryKey = 'getMe';

export const useMe = (option?: Record<string, any>) => useQuery([getMeQueryKey], () => getMe()
  .then((result: AxiosResponse<User>) => result.data), {
  refetchOnWindowFocus: false, retry: false,
});
