import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PurchaseCreate from 'components/Purchase/Create';
import PurchaseList from 'components/Purchase/List';
import Category from 'components/Category/Create';
import ProductList from 'components/Product/List';
import ProductEdit from 'components/Product/Edit';
import Product from 'components/Product/Create';
import OrderList from 'components/Order/List';
import SignIn from 'components/Auth/SignIn';
import OrderDetails from 'components/Order/Details';
import CategoryList from 'components/Category/List';
import CategoryEdit from 'components/Category/Edit';
import { Redirect } from 'components/Redirect/Create';
import { RedirectList } from 'components/Redirect/List';
import { RedirectEdit } from 'components/Redirect/Edit';
import { UserList } from 'components/User/List';

import routes from 'config/routes';
import Home from 'components/Home';

import Layout from '../Layout/Layout';
import ProtectedRoute from './ProtectedRoute';

const ProtectedRoutes = [
  {
    path: routes.HOME,
    element: <Home />,
  },
  {
    path: routes.PRODUCT_CREATE,
    element: <Product />,
  },
  {
    path: routes.PURCHASE_CREATE,
    element: <PurchaseCreate />,
  },
  {
    path: routes.PURCHASE_LIST,
    element: <PurchaseList />,
  },
  {
    path: routes.PRODUCT_LIST,
    element: <ProductList />,
  },
  {
    path: routes.PRODUCT_EDIT,
    element: <ProductEdit />,
  },
  {
    path: routes.ORDER_LIST,
    element: <OrderList />,
  },
  {
    path: routes.USER_LIST,
    element: <UserList />,
  },
  {
    path: routes.CATEGORY_CREATE,
    element: <Category />,
  },
  {
    path: routes.ORDER_DETAILS,
    element: <OrderDetails />,
  },
  {
    path: routes.CATEGORY_LIST,
    element: <CategoryList />,
  },
  {
    path: routes.CATEGORY_EDIT,
    element: <CategoryEdit />,
  },
  {
    path: routes.REDIRECT_LIST,
    element: <RedirectList />,
  },
  {
    path: routes.REDIRECT_EDIT,
    element: <RedirectEdit />,
  },
  {
    path: routes.REDIRECT_CREATE,
    element: <Redirect />,
  },
];

const PublicRoutes = [
  {
    path: routes.SIGN_IN,
    element: <SignIn />,
  },
];

const Navigation = () => (
  <BrowserRouter>
    <Routes>
      {
        ProtectedRoutes.map((r) => (
          <Route path={r.path} element={<ProtectedRoute />} key={r.path}>
            <Route
              path={r.path}
              element={(
                <Layout>
                  {r.element}
                </Layout>
              )}
            />
          </Route>
        ))
      }
      {
        PublicRoutes.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))
      }
    </Routes>
  </BrowserRouter>
);

export default Navigation;
