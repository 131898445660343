import { RedirectForm } from 'components/common/RedirectForm';
import { addRedirect } from 'services/redirect.service';
import { Button, Form, message, Typography } from 'antd';
import { useMutation } from 'react-query';
import { useCallback } from 'react';

export const Redirect = () => {
  const [form] = Form.useForm();
  const { mutateAsync: add } = useMutation(addRedirect);

  const handleAddRedirect = useCallback((data) => {
    add(data).then(() => {
      form.resetFields();
      message.success('Redirect successfully added!');
    });
  }, []);

  return (
    <div>
      <Typography.Text type='secondary'>Redirect create</Typography.Text>
      <RedirectForm form={form} onFinish={handleAddRedirect} />
      <Button onClick={form.submit}>Save</Button>
    </div>
  );
};
