import { useCallback, useMemo, useState } from 'react';
import { Button, Steps, Row, Col, message } from 'antd';

import Routes from 'config/routes';
import { useProviders, useProductActions } from 'hooks/api';
import { useNavigate } from 'react-router-dom';
import { toBase64 } from 'helpers/index';

import { Provider, ProductList, CreateProduct } from './components';
import styles from './index.module.scss';

const { Step } = Steps;

const Product = () => {
  const [provider, setProvider] = useState('');
  const [product, setProduct] = useState<any>(null);
  const [current, setCurrent] = useState(0);
  const { create } = useProductActions();
  const { data } = useProviders();
  const router = useNavigate();

  const next = useCallback(async ({ action, value }: { action: string, value: any }) => {
    if (action === 'provider' && value?.check) {
      setProduct({
        categories: [{ name: '' }],
        originPrice: 0,
        max_price: 0,
        min_price: 0,
        regions: [{ name: 'United States', code: 'US' }],
      });
      return setCurrent(current + 2);
    }
    if (action === 'provider') setProvider(value.provider);
    if (action === 'product') setProduct(value);
    if (action === 'createProduct') {
      const {
        description, name, values, percentage_of_buying_price: precentageOfBuingPrice, sku, originalName, region, file,
        category, howToUse, visible,
      } = value;
      // const bodyFormData = new FormData();
      const image = await toBase64(file.originFileObj);
      const productOption = {
        providers: [{ name: provider, sku }],
        precentageOfBuingPrice,
        originalName,
        region,
        store: ['steam'],
        description,
        category,
        howToUse,
        visible,
        values,
        image,
        name,
      };
      // const bodyFormData = new FormData();
      //
      // bodyFormData.append('providers[]', JSON.stringify({ name: provider, sku }));
      // bodyFormData.append('precentageOfBuingPrice', precentageOfBuingPrice);
      // bodyFormData.append('originalName', originalName);
      // bodyFormData.append('region', JSON.stringify(region));
      // bodyFormData.append('store[]', 'steam');
      // bodyFormData.append('description', description);
      // bodyFormData.append('category', category);
      // bodyFormData.append('values', JSON.stringify(values));
      // values.forEach((v) => {
      //   bodyFormData.append('values[]', v);
      // });
      // bodyFormData.append('name', name);
      // bodyFormData.append('image', file);
      return create(productOption).then(() => {
        router(Routes.HOME);
        message.success('Product created');
      }).catch((e) => {
        message.error(e?.response?.data?.message || e?.request?.statusText || e?.message);
      });
    }
    return setCurrent(current + 1);
  }, [provider, create, router]);

  const STEPS = useMemo(() => {
    if (!data) return [];
    return [
      {
        title: 'Select provider',
        content: 'First-content',
        render: () => <Provider providers={data} next={next} />,
      },
      {
        title: `${provider} list` || 'Product list',
        content: 'Second-content',
        render: () => <ProductList provider={provider} next={next} />,
      },
      {
        title: 'Create new product',
        content: 'Last-content',
        render: () => (<CreateProduct product={product || []} next={next} />),
      },
    ];
  }, [data, provider, product]);

  const prev = () => setCurrent(current - 1);

  const currentStep = useMemo(() => (STEPS.length ? STEPS[current].render() : null), [current, STEPS]);

  const stepsRender = useMemo(() => (
    <Col span={24}>
      <Row gutter={[16, 16]}>
        <>
          <Steps current={current}>
            {STEPS?.map((item) => (<Step key={item.title} title={item.title} />))}
          </Steps>
          {currentStep}
        </>
      </Row>
    </Col>
  ), [currentStep]);

  const stepsButtonRender = useMemo(() => (
    <div>
      {current > 0 && (
        <Button className={styles.btn} onClick={() => prev()}>
          <div>
            <span className={styles.backButton}>&#60;</span>
            Back
          </div>
        </Button>
      )}
    </div>
  ), [current]);

  return (
    <Row gutter={[16, 16]}>
      {stepsButtonRender}
      {stepsRender}
    </Row>
  );
};
export default Product;
