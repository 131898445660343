import { Card, Col, Row, Tag, Spin, Typography, Form, message } from 'antd';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProductForm } from 'components/common/ProductForm';
import { useProduct, useCategoryList, useProductActions } from 'hooks/api';
import { useProductForm } from 'hooks/common';

import { toBase64 } from 'helpers/index';

import styles from '../Create/components/CreateProduct/index.module.scss';

const { Title, Text } = Typography;

const ProductEdit = () => {
  const { id } = useParams();
  // @ts-ignore
  const { data: product, isLoading: productLoading } = useProduct(id);
  const { edit } = useProductActions();
  const { data: categories } = useCategoryList();
  const [formRef] = Form.useForm();
  // @ts-ignore
  const { productFormation } = useProductForm(product);

  const handleOnFinish = useCallback(async (form: Record<string, any>, file: any) => {
    const result = productFormation(form);
    const image = file ? await toBase64(file.originFileObj) : product.image;
    edit({ id, product: { ...result, image, providers: product.providers } })
      .then(() => message.success('Product updated!'));
  }, [product]);

  const fillForm = useCallback(() => {
    const values = product.values.map((v) => ({
      'seo-translation': v.seo.translation,
      seoDescription: v.seo.description,
      valueDescription: v.description,
      purchasePrice: v.purchasePrice,
      translation: v.translation,
      originPrice: v.originPrice,
      outOfStock: v.outOfStock,
      keywords: v.seo.keywords,
      seoTitle: v.seo.title,
      discount: v.discount,
      display: v.display,
      visible: v.visible,
      region: v.region,
      price: v.price,
      link: v.link,
    }));

    formRef.setFieldsValue({
      name: product.name,
      originalName: product.originalName,
      description: product.description,
      category: product.category.name,
      root: { translation: product.translation },
      howToUse: product.howToUse,
      visible: product.visible,
      values,
    });
  }, [product]);

  useEffect(() => {
    if (formRef && product) fillForm();
  }, [product]);

  const fetchContent = useMemo(
    () => (
      (productLoading ? <Spin /> : false)
    ),
    [productLoading],
  );

  return (
    <div>
      {
        fetchContent || (
          <Card title={product.category.name} className={styles.productCard}>
            <Row className={styles.valueInfo}>
              <Col>
                Min price:
                {' '}
                <Tag color='cyan'>
                  {
                  // @ts-ignore
                  product?.min_price
                }
                </Tag>
              </Col>
              <Col>
                Max price:
                {' '}
                <Tag color='cyan'>
                  {
                  // @ts-ignore
                  product?.max_price
                  }
                </Tag>
              </Col>
            </Row>
            <ProductForm
              onFinish={handleOnFinish}
              type='edit'
              categories={categories}
              product={product}
              formRef={formRef}
            />
          </Card>
        )
      }
    </div>
  );
};

export default ProductEdit;
