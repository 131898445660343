import { Input, Modal, Typography, Form, Card, Button, Tag, message } from 'antd';
import { useCallback } from 'react';
import { useOrderActions } from 'hooks/api';

import { CodeItem } from './components/CodeItem';
import styles from './index.module.scss';

const { Text } = Typography;

export interface SendCardModalProps {
  isModalOpen: boolean,
  toggleModal: () => void,
  order: Order.Item
}
const SendCardModal = ({ order, toggleModal, isModalOpen }: SendCardModalProps) => {
  const [form] = Form.useForm();
  const { process } = useOrderActions();

  const handleOk = useCallback(async () => {
    console.log(await form.validateFields());
    try {
      process(await form.validateFields())
        .then(() => message.success('Order successfully delivered!'))
        .catch(() => message.error('Order delivery error'));
      toggleModal();
    } catch (e) {
      message.error('Form have some error');
    }
  }, [form, order?._id]);

  const renderModalAmount = useCallback((productIdx, amountIdx, item) => (
    <Form.List name={['products', productIdx, 'codes', amountIdx]} initialValue={[{}]}>
      {(fields, control) => (
        <Card
          className={styles.card}
          title={(
            <Tag>
              Item
              {' '}
              {amountIdx + 1}
            </Tag>
          )}
        >
          {fields.map((field) => <CodeItem field={field} />)}
          <Button onClick={() => control.add()}>Add type</Button>
        </Card>
      )}
    </Form.List>
  ), []);

  return (
    <Modal title='Process order' visible={isModalOpen} onCancel={toggleModal} onOk={handleOk}>
      <Form layout='vertical' form={form}>
        <Form.Item hidden name={['orderId']} initialValue={order?._id}>
          <Input />
        </Form.Item>
        {order?.items?.map((item, idx) => (
          <div key={idx.toString()}>
            <Form.Item hidden name={['products', idx, 'productId']} initialValue={item.product._id}>
              <Input />
            </Form.Item>
            <Form.Item hidden name={['products', idx, 'provider']} initialValue='ezpaypin'>
              <Input />
            </Form.Item>
            <Form.Item hidden name={['products', idx, 'price']} initialValue={item.originPrice}>
              <Input />
            </Form.Item>
            <Card
              className={styles.card}
              title={(
                <p>
                  <Text code>
                    {' '}
                    $
                    {item.originPrice / 100}
                    {' '}
                    {item?.product?.name}
                  </Text>
                </p>
              )}
            >
              {Array.from(new Array(item.amount)).map((amount, amountIdx) => renderModalAmount(idx, amountIdx, item))}
            </Card>
          </div>
        ))}
      </Form>
    </Modal>
  );
};

export default SendCardModal;
