import { API_URLS } from 'config/constants';
import httpServices from './http.service';

export const getCategories = () => (httpServices.get(API_URLS.CATEGORY.ALL));
export const createCategory = (body: any) => (httpServices.post(API_URLS.CATEGORY.CREATE, body));

export const changeCategoryVisible = ({ category, visible }: {category: string, visible: boolean}) => httpServices.patch(
  API_URLS.CATEGORY.CHANGE_VISIBLE.replace('[name]', category),
  { visible },
);

export const getCategory = (category: string) => (
  httpServices.get(API_URLS.CATEGORY.GET.replace('[name]', category))
);

export const editCategory = ({ name, category }: { name: string, category: Category }) => (
  httpServices.patch(API_URLS.CATEGORY.EDIT.replace('[name]', name), category)
);
