export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:9000';
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';

// export const BASE_URL = 'B';
export const API_URLS = {
  AUTH: {
    SIGN_IN: '/auth/admin/sign-in',
    ME: '/auth/admin/me',
  },
  PROVIDER: {
    PRODUCTS: '/admin/provider/products',
    ALL: '/admin/provider',
  },
  STORAGE: {
    UPLOAD: '/storage/upload',
  },
  PURCHASE: {
    PRODUCTS: '/admin/purchase/products',
    CREATE: '/admin/purchase',
    ALL: '/admin/purchase',
  },
  PRODUCT: {
    CREATE: '/admin/product',
    ALL: '/admin/product/list',
    GET: '/admin/product/[id]',
    EDIT: '/admin/product/[id]',
  },
  ORDER: {
    ALL: '/admin/order/list',
    BY_ID: '/admin/order/',
  },
  CATEGORY: {
    CREATE: '/admin/product/category',
    ALL: '/admin/product/category',
    CHANGE_VISIBLE: 'admin/product/category/[name]/visible',
    GET: '/admin/product/category/[name]',
    EDIT: '/admin/product/category/[name]',
  },
  MANUAL_DELIVERY: {
    PROCESS: '/admin/order/process',
  },
  USER: {
    MARK_IS_FRAUD: '/admin/user/fraud',
    LIST: '/admin/user',
  },
  METRIC: {
    GET_METRIC: '/admin/metric',
  },
  REDIRECT: {
    LIST: '/admin/redirect',
    GET: '/admin/redirect/[id]',
    CREATE: '/admin/redirect',
    EDIT: '/admin/redirect/[id]',
  },
};

export const APP_URLS = {
  ORDER_VIEW: '/order/details/[id]',
  USER_VIEW: '/user/details/[id]',
};

export const DEFAULT_VALUES = [
  { value: '500' }, { value: '1000' }, { value: '2500' }, { value: '5000' }, { value: '10000' },
];

export const ORDER_STATUS = {
  DELIVERED: { status: 'Delivered', color: '#b7eb8f' },
  PAID: { status: 'Paid', color: '#69c0ff' },
  PENDING: { status: 'Pending', color: '#fadb14' },
  CREATED: { status: 'Created', color: '#5c60e5' },
  FAIL: { status: 'Fail', color: '#ff4d4f' },
  REFUND: { status: 'Refund', color: '#4ac9db' },
  CANCELED: { status: 'Canceled', color: '#D3D3D3' },
};

// eslint-disable-next-line no-shadow
export enum CardType {
  Pin = 'cardType:pin',
  CardNumber = 'cardType:number',
  Serial = 'cardType:serial'
}

export const CardTypeMap = {
  [CardType.Pin]: 'Pin code',
  [CardType.CardNumber]: 'Card number',
  [CardType.Serial]: 'Serial',
};
