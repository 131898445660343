import { AutoComplete, Card, Col, Form, Input, Row, Select, Switch } from 'antd';
import TranslateFormWrapper from 'components/common/TranslationForm';
import { DEFAULT_VALUES } from 'config/constants';
import { useMemo } from 'react';

import styles from '../ValueForm/index.module.scss';

export interface ValueFormItemProps {
  category: Category,
  field: any
}
const ValueFormItem = ({ field, category }: ValueFormItemProps) => {
  const regions = useMemo(() => category.regions.map((r) => ({ label: r.name, value: r.code })), [category]);

  return (
    <Row key={field.key}>
      <Col span={12}>
        <Card className={styles.valueContainer} title='Product value'>
          <Form.Item
            label='Link'
            name={[field.name, 'link']}
          >
            <Input placeholder='Link' />
          </Form.Item>
          <Form.Item
            name={[field.name, 'region']}
            label='region'
          >
            <Select
              options={regions}
              placeholder='region'
            />
          </Form.Item>
          <Form.Item
            label='Origin price'
            name={[field.name, 'originPrice']}
          >
            <AutoComplete
              placeholder='Original price'
              options={DEFAULT_VALUES}
            />
          </Form.Item>
          <Form.Item
            label='Purchase price'
            name={[field.name, 'purchasePrice']}
          >
            <Input placeholder='Purchase price' />
          </Form.Item>
          <Form.Item
            label='Price'
            name={[field.name, 'price']}
          >
            <Input placeholder='Price' />
          </Form.Item>
          <Form.Item
            label='Display'
            name={[field.name, 'display']}
          >
            <Input placeholder='Display' />
          </Form.Item>
          <Form.Item
            label='Discount'
            name={[field.name, 'discount']}
          >
            <Input placeholder='Discount' />
          </Form.Item>
          <Form.Item
            label='Description'
            name={[field.name, 'valueDescription']}
          >
            <Input.TextArea placeholder='description' />
          </Form.Item>
          <Form.Item
            label='Visible'
            valuePropName='checked'
            name={[field.name, 'visible']}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label='Out of stock'
            valuePropName='checked'
            name={[field.name, 'outOfStock']}
          >
            <Switch />
          </Form.Item>
          <TranslateFormWrapper
            field={field}
            items={[{
              name: 'description',
              item: <Input.TextArea placeholder='description translation' />,
            }]}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card title='Product SEO value'>
          <Form.Item
            label='Meta title'
            name={[field.name, 'seoTitle']}
          >
            <Input placeholder='Meta title' />
          </Form.Item>
          <Form.Item
            label='Description'
            name={[field.name, 'seoDescription']}
          >
            <Input.TextArea placeholder='Meta description' />
          </Form.Item>
          <Form.Item
            label='Keywords'
            name={[field.name, 'keywords']}
          >
            <Input.TextArea placeholder='Keywords' />
          </Form.Item>
          <TranslateFormWrapper
            field={field}
            prefix='seo'
            items={[
              {
                name: 'seoTitle',
                label: 'Title',
                item: <Input placeholder='Seo title translation' />,
              },
              {
                name: 'seoDescription',
                label: 'Description',
                item: <Input.TextArea placeholder='Description' />,
              },
              {
                name: 'seoKeywords',
                label: 'Keywords',
                item: <Input.TextArea placeholder='Keywords' />,
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ValueFormItem;
