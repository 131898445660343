import { useOrderActions, useOrderDetails } from 'hooks/api';
import { useParams } from 'react-router-dom';
import { Typography, Row, Modal, Input } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { GOOGLE_API_KEY } from 'config/constants';

import styles from './index.module.scss';
import { PaymentSystem } from './components/PaymentSystem';
import { OtherOrders } from './components/OtherOrders';
import { OrderInfo } from './components/OrderInfo';
import { UserInfo } from './components/UserInfo';
import { Accounts } from './components/Accounts';
import { Location } from './components/Location';
import SendCardModal from './components/SendCardModal';

const { Title, Text } = Typography;

export interface OrderForm {
  originPrice: number,
  productId: string,
  cods: Record<number, string>[]
}
const Details = () => {
  const { id } = useParams();
  const { data: order } = useOrderDetails({ id });
  const [isModalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState<OrderForm[]>([]);

  const toggleModal = useCallback(() => {
    setModalOpen((s) => !s);
  }, []);

  const handleForm = useCallback((productId, originPrice, idx, value) => {
    setForm((s) => {
      const foundProduct = s.findIndex((f) => f?.productId === productId && f?.originPrice === originPrice);
      if (foundProduct !== -1) {
        const foundIdx = s[foundProduct].cods.findIndex((c) => c[idx]);
        if (foundIdx !== -1) s[foundProduct].cods[foundIdx] = { [idx]: value };
        else s[foundProduct].cods.push({ [idx]: value });
      } else s.push({ productId, originPrice, cods: [{ [idx]: value }] });
      return [...s];
    });
  }, []);

  const renderModalAmount = useCallback((amountIdx, item) => (
    <p key={amountIdx.toString()}>
      <Input
        placeholder='...code'
        onChange={(e) => {
          handleForm(item.product._id, item.originPrice, amountIdx, e.target.value);
        }}
      />
    </p>
  ), [handleForm]);

  return (
    <div>
      <Title level={2}>Order details</Title>
      {
        order ? (
          <Row className={styles.container}>
            <UserInfo order={order} className={styles.card} />
            <OrderInfo order={order} className={styles.card} toggleModal={toggleModal} />
            <PaymentSystem order={order} className={styles.card} />
            <Location location={order.location} className={styles.card} />
            <OtherOrders order={order} />
            <Accounts users={order.accounts} />
          </Row>
        ) : null
      }

      <SendCardModal isModalOpen={isModalOpen} toggleModal={toggleModal} order={order} />
    </div>
  );
};

export default Details;
