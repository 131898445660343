import { usePagination } from 'hooks/common';
import { useOrdersList } from 'hooks/api';
import { Card, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { ORDER_STATUS } from 'config/constants';
import dayjs from 'dayjs';

import styles from './index.module.scss';

export interface OtherOrdersProps {
  order: Order.Item,
}

const limit = 5;

const columns = [
  {
    title: 'id',
    render: (record: Order.Item) => (
      <Link to={`/order/details/${record._id}`}>
        {record._id}
      </Link>
    ),
  },
  {
    title: 'date',
    render: (record: Order.Item) => dayjs(record.createdAt).format('MMMM D, YYYY h:mm A'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '_id',
    render: (status) => <Tag color={ORDER_STATUS[status.toUpperCase()].color}>{status}</Tag>,
  },
];

export const OtherOrders = ({ order }: OtherOrdersProps) => {
  const { page, setPage } = usePagination(1);
  const { data, isLoading } = useOrdersList({
    user: order.user._id, skip: (page - 1) * limit, limit, exclude: [order._id],
  });

  const handlePageChange = useCallback((p: number) => {
    setPage(p);
  }, [setPage]);

  return (
    <Card
      title='Other order'
    >
      <Table
        className={styles.table}
        dataSource={data?.data}
        columns={columns}
        loading={isLoading}
        pagination={{
          onChange: handlePageChange,
          pageSize: limit,
          total: data?.total,
          current: page,
        }}
      />
    </Card>
  );
};
