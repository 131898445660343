import { useQuery } from 'react-query';
import { getPurchaseProducts } from 'services/purchase.service';
import { AxiosResponse } from 'axios';

export const getPurchaseProductsQueryKey = 'getPurchaseProducts';

export const usePurchaseProducts = (provider: string) => useQuery([getPurchaseProductsQueryKey, provider], () => getPurchaseProducts(provider)
  .then((result: AxiosResponse<string[]>) => result.data || []), {
  refetchOnWindowFocus: false, retry: false, enabled: !!provider,
});
