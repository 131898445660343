import classNames from 'classnames';

import styles from './index.module.scss';

export interface KeyValueProps {
  className?: string;
  title: string;
  value: string;
}
export const KeyValue = ({ title, value, className }: KeyValueProps) => (
  <div className={classNames(styles.keyValue, className)}>
    <div className={styles.title}>
      {title}
      :
    </div>
    <div className={styles.value}>{value}</div>
  </div>
);
