import CategoryCreateForm from 'components/common/CateogyCreateForm';
import { useCategory } from 'hooks/api';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { Button, Form, message } from 'antd';
import { useMutation } from 'react-query';
import { editCategory } from 'services/category.service';

import styles from '../../common/CateogyCreateForm/index.module.scss';

const CategoryEdit = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { data: category } = useCategory(id!);
  const { mutateAsync: update } = useMutation(editCategory);

  const formationCategory = useCallback((c: Category) => ({
    seoTranslation: { translation: c?.seo?.translation },
    root: { translation: c.translation },
    categoryDescription: c.description,
    description: c?.seo?.description,
    keywords: c?.seo?.keywords,
    regions: c.regions,
    title: c?.seo?.title,
    image: c.image,
    link: c.link,
    name: c.name,
  }), []);

  useEffect(() => {
    if (category) {
      form.setFieldsValue(formationCategory(category));
    }
  }, [category]);

  const handleFinish = useCallback((result) => {
    const payload = {
      name: result.name,
      seo: {
        translation: result?.seoTranslation?.translation,
        description: result.description,
        keywords: result.keywords,
        title: result.title,
      },
      description: result.categoryDescription,
      translation: result.root.translation,
      regions: result.regions,
      link: result.link,
      image: result.image,
    };
    update({ name: id, category: payload }).then(() => {
      message.success('Category updated!');
    });
  }, [update]);

  return (
    <div>
      <CategoryCreateForm form={form} onFinish={handleFinish} />
      <Button className={styles.save} onClick={() => form.submit()} type='primary'>Update category</Button>
    </div>
  );
};

export default CategoryEdit;
