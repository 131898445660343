import { List, Typography, Pagination, Avatar, Tag, Switch } from 'antd';
import { useProductsList } from 'hooks/api';
import { EditFilled } from '@ant-design/icons';
import { useCallback } from 'react';
import APP_ROUTES from 'config/routes';

import styles from './index.module.scss';

const { Text } = Typography;

const ProductList = () => {
  const { data, isLoading } = useProductsList();

  const renderItem = useCallback((item: Product.Item, idx) => {
    const descriptionRender = (
      <div className={styles.description}>
        <div className={styles.store}>
          {item.store.map((s, storeIndex) => (
            <div key={storeIndex.toString()}>
              <Switch defaultChecked />
              <span className={styles.name}>{s}</span>
            </div>
          ))}
        </div>
        <div>
          {item.values.map((i, valueIdx) => (
            <Tag key={valueIdx.toString()} className={styles.tag}>
              $
              {' '}
              {i.price / 100}
            </Tag>
          ))}
        </div>
      </div>
    );

    const titleRender = (
      <div>
        {item.name}
        <span>
          {item.providers.map((i, providerIdx) => (
            <span key={providerIdx.toString()} className={styles.title}>
              (
              {i.name}
              {item.providers.length - 1 === idx ? null : ','}
              {' '}
              )
            </span>
          ))}
        </span>
        <a
          aria-label='edit'
          className={styles.edit}
          href={APP_ROUTES.PRODUCT_EDIT.replace(':id', item._id)}
        >
          <EditFilled />
        </a>
      </div>
    );

    return (
      <List.Item className={styles.list} key={idx.toString()}>
        <List.Item.Meta
          avatar={<Avatar src={item.image} />}
          title={titleRender}
          description={descriptionRender}
        />
      </List.Item>
    );
  }, []);

  return (
    <div>
      <Text type='secondary'>Product list</Text>
      <List
        className={styles.listWrapper}
        itemLayout='vertical'
        size='large'
        dataSource={data?.data}
        renderItem={renderItem}
        loading={isLoading}
      />
    </div>
  );
};

export default ProductList;
