import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = (initialPage: number = 1, pageKey: string = 'page') => {
  const [page, setPage] = useState(initialPage);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const paramsPage = Number(searchParams.get(pageKey));
    if (paramsPage && paramsPage !== page) {
      setPage(paramsPage);
    }
  }, []);

  const handleSetPage = useCallback((p: number) => {
    setPage(p);
    setSearchParams({ [pageKey]: p.toString() });
  }, []);

  return { page, setPage: handleSetPage };
};
