import { useProviderProduct } from 'hooks/api';
import {
  Avatar, List, Row, Col, Button, Typography, Pagination,
} from 'antd';

import { NextStep } from '../../../interfaces';

import styles from './index.module.scss';

const { Text } = Typography;

interface ProductListProps {
    provider: string;
    next: NextStep
}
const ProviderProductList = ({ provider, next }: ProductListProps) => {
  const { data } = useProviderProduct(provider);

  const descriptionRender = (item: Product.Provider) => (
    <Row align='middle' className={styles.metadata}>
      <Col span={23}>
        <div>
          max price:
          {' '}
          {item.max_price}
        </div>
        <div>
          min price:
          {' '}
          {item.min_price}
        </div>
        <div>
          percentage of buying price:
          {' '}
          {item.percentage_of_buying_price}
        </div>
      </Col>
      <Col span={1}>
        <Button type='primary' onClick={() => next({ action: 'product', value: item })}>Next</Button>
      </Col>
    </Row>
  );

  return (
    <div className={styles.productList}>
      <Text type='secondary'> Decide which product you want and click NEXT</Text>
      <List
        itemLayout='vertical'
        size='large'
        dataSource={data?.results}
        renderItem={(item: Product.Provider) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.image} />}
              title={item.categories[0].name}
              description={descriptionRender(item)}
            />
          </List.Item>
        )}
      />
      <Pagination className={styles.pagination} defaultCurrent={1} total={data?.count || 1} />
    </div>
  );
};

export default ProviderProductList;
