import { getCategory } from 'services/category.service';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

export const getCategoryQueryKey = 'getCategory';

export const useCategory = (category: string) => useQuery([getCategoryQueryKey, category], () => getCategory(category)
  .then((result: AxiosResponse<Category>) => result.data), {
  refetchOnWindowFocus: false, retry: false,
});
