'use client';

import classNames from 'classnames';
import { ChangeEvent, useCallback, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

interface SearchProps {
  onSearch: (value: string) => void;
  className?: string;
  placeholder?: string;
}

export const Search = ({ onSearch, className, placeholder = 'Search' }: SearchProps) => {
  const [value, setValue] = useState<string>('');

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (!e.target.value) onSearch('');
  };

  const onSearchHandler = useCallback(() => {
    if (value) onSearch(value);
  }, [onSearch, value]);

  const clearSearch = useCallback(() => {
    onSearch('');
    setValue('');
  }, [onSearch]);

  return (
    <div className={classNames(className, styles.searchWrapper, { [styles.searchActive]: value.length > 0 })}>
      <Input
        placeholder={placeholder}
        value={value}
        type='text'
        onPressEnter={onSearchHandler}
        onInput={onInput}
      />
      {value && <CloseOutlined className={styles.cancel} onClick={clearSearch} />}
      <div className={styles.searchButton} onClick={onSearchHandler} role='presentation'>
        <SearchOutlined color='#546E8C' className={styles.searchIcon} />
      </div>
    </div>
  );
};
