import { useMutation } from 'react-query';
import { createProduct, editProduct } from 'services/product.service';

export const useProductActions = () => {
  const { mutateAsync: create } = useMutation(createProduct);
  const { mutateAsync: edit } = useMutation(editProduct);

  return {
    create,
    edit,
  };
};
