import httpServices from 'services/http.service';
import { API_URLS } from 'config/constants';

export const getPurchaseProducts = (provider: string) => (
  httpServices.get(API_URLS.PURCHASE.PRODUCTS, { params: { provider } })
);

export const getPurchases = (params) => (
  httpServices.get(API_URLS.PURCHASE.ALL, { params })
);

export const createPurchase = (data: { provider: string, price: number, sku: number, amount: number }) => (
  httpServices.post(API_URLS.PURCHASE.CREATE, data)
);
