import {
  InputNumber, Button, message,
} from 'antd';
import { useMutation } from 'react-query';
import { useEffect, useState } from 'react';

import { createPurchase } from 'services/purchase.service';

import styles from './index.module.scss';

interface InternalProductProps { provider: string, price: number, sku: number }

const InternalProduct = ({
  provider, price, sku,
}: InternalProductProps) => {
  const [amount, setAmount] = useState(0);
  const { mutateAsync: createPurchaseProduct, isLoading, isSuccess } = useMutation(createPurchase);

  useEffect(() => {
    if (isSuccess) {
      message.success('Order created');
      setAmount(0);
    }
  }, [isSuccess, setAmount]);

  return (
    <div className={styles.metadata}>
      <div>{price}</div>
      <div>
        <InputNumber value={amount} onChange={(val: number) => setAmount(val)} />
        <Button
          type='primary'
          onClick={() => createPurchaseProduct({
            provider, price, sku, amount,
          })}
          loading={isLoading}
        >
          Buy
        </Button>
      </div>
    </div>
  );
};

export default InternalProduct;
