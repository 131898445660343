import { QueryClient, QueryClientProvider } from 'react-query';
import 'antd/dist/antd.min.css';

import Navigation from './components/common/navigation/navigation';
import './styles/App.scss';
import { SocketProvider } from './contexts/Socket.context';

const queryClient = new QueryClient();

const App = () => (
  <div className='App'>
    <QueryClientProvider client={queryClient}>
      {/* <SocketProvider> */}
      <Navigation />
      {/* </SocketProvider> */}
    </QueryClientProvider>
  </div>
);

export default App;
