import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Form, Input, Row, Select, Space } from 'antd';
import { ReactNode, useCallback, useMemo } from 'react';

import styles from './index.module.scss';

export interface TranslateFormItem {
  item: ReactNode,
  label?: string,
  name: string,
}
export interface TranslateFormWrapperProps {
  items: TranslateFormItem[],
  prefix?: string;
  field: any;
}

const languageOptions = [
  { label: 'Ukraine', value: 'ua' },
  { label: 'Russian', value: 'ru' },
];

const TranslateFormWrapper = ({ prefix, items, field }: TranslateFormWrapperProps) => {
  const renderCollapseHeader = useCallback((index, remove) => (
    <Row className={styles.valueHeader}>
      <span>{`${index + 1} translation`}</span>
      <Button className={styles.removeButton} onClick={() => remove(index)}>Remove</Button>
    </Row>
  ), []);

  const renderItem = useCallback((e: TranslateFormItem, f, idx: number) => (
    <Form.Item
      label={e.label || e.name}
      name={[f.name, e.name]}
      key={idx.toString()}
    >
      {e.item}
    </Form.Item>
  ), []);

  const listName = useMemo(() => (prefix ? `${prefix}-translation` : 'translation'), [prefix]);

  return (
    field?.name !== 'undefined'
      ? (
        <Form.List name={[field.name, listName]}>
          {(translation, { add, remove }) => (
            <div>
              {translation.map((f, index) => (
                <Collapse defaultActiveKey={['1']} key={field.key}>
                  <Collapse.Panel header={renderCollapseHeader(index, remove)} key={index} className={styles.valueRow}>
                    <Card className={styles.form}>
                      <Form.Item
                        label='language'
                        name={[f.name, 'language']}
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder='Select language'
                          options={languageOptions}
                        />
                      </Form.Item>
                      {items.map((e, idx) => renderItem(e, f, idx))}
                    </Card>
                  </Collapse.Panel>
                </Collapse>
              ))}
              <Button onClick={(e) => { e.preventDefault(); add(); }} className={styles.addValueButton}>
                <PlusOutlined />
              </Button>
            </div>
          )}
        </Form.List>
      ) : null
  );
};

export default TranslateFormWrapper;
