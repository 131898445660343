import { getRedirects } from 'services/redirect.service';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

export const getRedirectsQueryKey = 'getRedirects';

export const useRedirects = () => useQuery([getRedirectsQueryKey], () => getRedirects()
  .then((result: AxiosResponse<Redirect>) => result.data), {
  refetchOnWindowFocus: false, retry: false,
});
