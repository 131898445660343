import classNames from 'classnames';
import { Alert, Card } from 'antd';
import { useCallback } from 'react';
import { KeyValue } from 'components/common/KeyValue';

import styles from './index.module.scss';

export interface PaymentSystemProps {
  order: Order.Item,
  className: string;
}
export const PaymentSystem = ({ order, className }: PaymentSystemProps) => {
  const { paymentSystem } = order;
  const renderErrorItem = useCallback((item, idx) => (
    <Alert
      message={item.code}
      description={item.description}
      type='error'
      showIcon
    />
  ), []);

  return (
    paymentSystem ? (
      <Card
        title='Payment system info'
        loading={!paymentSystem.type}
        className={classNames(styles.card, className)}
      >
        <KeyValue title='Type' value={paymentSystem.type} />
        <KeyValue title='Order id' value={paymentSystem.orderId} />
        <KeyValue title='Payment id' value={paymentSystem.paymentId} />
        {
        paymentSystem.errors.length ? (
          <Card title='errors info'>
            <div className={styles.items}>
              {paymentSystem.errors.map(renderErrorItem)}
            </div>
          </Card>
        ) : null
      }
      </Card>
    ) : null
  );
};
