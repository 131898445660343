import { Avatar, Col, List, Row, Typography } from 'antd';
import { usePurchaseProducts } from 'hooks/api';

import InternalProduct from './InternalProduct';
import styles from './index.module.scss';

const { Text } = Typography;

const PurchaseProductList = ({ provider }: { provider: string }) => {
  const { data } = usePurchaseProducts(provider);

  const descriptionRender = (item: Product.Item) => (
    <Row>
      <Col flex={1}>
        { item.values.map((v) => (
          <InternalProduct key={v._id} provider={provider} price={v.originPrice} sku={item.sku} />
        )) }
      </Col>
    </Row>
  );

  return (
    <div className={styles.purchaseProductList}>
      <Text type='secondary'> Decide which product you want and click NEXT</Text>
      <List
        itemLayout='horizontal'
        size='large'
        dataSource={data}
        pagination={{
          pageSize: 4,
        }}
        renderItem={(item: Product.Item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item?.image} />}
              title={item?.originalName || item?.name}
              description={descriptionRender(item)}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default PurchaseProductList;
