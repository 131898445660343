import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { Button, Pagination, Select, Table, Tag } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { useOrdersList } from 'hooks/api';
import { APP_URLS, ORDER_STATUS } from 'config/constants';
import { Link } from 'react-router-dom';
import { usePagination } from 'hooks/common';
import { ColumnsType } from 'antd/es/table';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { Search } from 'components/common/Search';
import { Filter } from 'components/common/Filter';

import styles from './index.module.scss';

const limit = 10;

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
const responsive: Breakpoint[] = ['sm', 'xl', 'lg', 'xxl', 'md'];

const OrderList = () => {
  const [term, setTerm] = useState<string>();
  const { page, setPage } = usePagination(1);
  const [filters, updateFilters] = useState<Record<string, string | number>>({});
  const { data, isLoading } = useOrdersList({ skip: (page - 1) * limit, limit, search: term, ...filters });
  const StatusFilerOptions = useMemo(() => Object.values(ORDER_STATUS)
    .map(({ status }) => ({ label: status, value: status })), []);

  const handleFilterChange = useCallback((filter: string, value: string| null) => {
    updateFilters((prev) => {
      const updater = { ...prev };
      if (value) updater[filter] = value;
      else delete updater[filter];
      return updater;
    });
    setPage(1);
  }, []);

  const handleSearch = useCallback((searchTerm: string) => {
    setTerm(searchTerm);
    setPage(1);
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: 'email',
      render: (record) => record?.user?.email,
      responsive,
    },
    {
      title: 'referrer',
      render: (record) => record?.user?.referrer,
      responsive,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (<span>{dayjs(createdAt).format('MMMM D, YYYY h:mm A')}</span>),
      responsive: [...responsive, 'xs'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={ORDER_STATUS[status.toUpperCase()].color}>{status}</Tag>,
      responsive: [...responsive, 'xs'],
    },
    {
      title: 'Actions',
      render: (record) => (
        <Link className={styles.action} to={APP_URLS.ORDER_VIEW.replace('[id]', record._id)}>
          <EyeFilled />
          <span>View</span>
        </Link>
      ),
      responsive: [...responsive],
    },
    {
      title: 'View',
      key: 'view',
      render: (record) => (
        <Link to={APP_URLS.ORDER_VIEW.replace('[id]', record._id)}>
          <EyeFilled />
        </Link>
      ),
      responsive: ['xs'],
    },
  ];

  return (
    <div>
      <div className={styles.controls}>
        <Filter
          onChange={handleFilterChange}
          options={StatusFilerOptions}
          placeholder='Status filter'
          className={styles.filter}
          filter='status'
        />
        <Search onSearch={handleSearch} className={styles.search} />
      </div>
      <Table
        columns={columns}
        dataSource={data?.data || []}
        pagination={false}
        loading={isLoading}
      />
      <Pagination
        className={styles.pagination}
        defaultCurrent={page}
        total={data?.total || 1}
        onChange={(p: number) => setPage(p)}
        current={page}
      />
    </div>
  );
};

export default OrderList;
