import { useCallback } from 'react';
import { message } from 'antd';

export const useProductForm = (product: Product.Provider) => {
  const formatValue = useCallback((values) => values.map((value) => {
    const { seoDescription, keywords, seoTitle, valueDescription, ...v } = value;

    return {
      ...v,
      purchasePrice: +v.purchasePrice,
      originPrice: +v.originPrice,
      region: v.region,
      seo: {
        translation: v?.['seo-translation'],
        description: seoDescription,
        title: seoTitle,
        keywords,
      },
      description: valueDescription,
      translation: v.translation,
      discount: +v.discount,
      price: +v.price,
    };
  }), []);

  const productFormation = useCallback((form: Record<string, any>) => {
    if (!form.values.length) return message.error('Values empty', 5);
    const emptyValues = form.values.some((v) => !v.price || !v.originPrice);
    if (emptyValues) return message.error('You have a value with zero', 5);
    const repeatedValues = form.values.some(
      (v, idx) => form.values.find(
        (val, idxSecond) => (val.originPrice === v.originPrice) && idx !== idxSecond,
      ),
    );
    if (repeatedValues) return message.error('You have the same value originPrice', 5);
    // const validValues = form.values.some((v) => (+v.originPrice > product.max_price || +v.originPrice < product.min_price));
    // if (validValues) return message.error('The values are not correct', 5);
    const category = !form.category ? {
      seo: {
        description: form['category-description'],
        keywords: form['category-keywords'],
        title: form['category-title'],
      },
      name: form['category-name'],
      description: form['category-categoryDescription'],
      translation: form['category-root'].translation,
    } : form.category;

    return {
      ...product,
      values: formatValue(form.values),
      originalName: form.originalName,
      translation: form.root.translation,
      description: form.description,
      percentage_of_buying_price: 0,
      howToUse: form.howToUse,
      visible: form.visible,
      name: form.name,
      category,
      sku: '',
    };
  }, [product]);

  return { productFormation };
};
