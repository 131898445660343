import { Col, Form, FormInstance, Input, Row } from 'antd';

export interface RedirectFormProps {
  form: FormInstance;
  onFinish: (v: any) => void;
}

export const RedirectForm = ({ form, onFinish }: RedirectFormProps) => (
  <Form form={form} onFinish={onFinish}>
    <Row>
      <Col span={24}>
        <Form.Item
          name='from'
          label='from'
        >
          <Input placeholder='from' />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name='to'
          label='to'
        >
          <Input placeholder='to' />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);
