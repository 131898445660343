import styles from './index.module.scss';

export interface ProfitProps {
  value: number
}

export const Profit = ({ value }: ProfitProps) => (
  <div className={styles.profit}>
    (
    <div className={styles.value}>
      $
      {value / 100}
    </div>
    )
  </div>
);
