export interface MapProps {
  lat: number;
  long: number;
  apiKey: string;
}
export const Map = ({ lat, long, apiKey }: MapProps) => {
  const location = !!(lat || long);
  return (
    location ? (
      <iframe
        title='map'
        loading='lazy'
        allowFullScreen
        referrerPolicy='no-referrer-when-downgrade'
        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${lat},${long}`}
      />
    ) : null
  );
};
