import { useMetric } from 'hooks/api';
import { Row } from 'antd';
import { useCallback } from 'react';

import { MetricItem } from './components/MetricItem';
import styles from './index.module.scss';
import { ORDER_STATUS } from '../../config/constants';

const metricMap = {
  users: { title: 'Users' },
  orders: { title: 'Orders' },
  deliveredOrders: { title: 'Delivered Orders', background: ORDER_STATUS.DELIVERED.color },
  paidOrders: { title: 'Paid Orders', background: ORDER_STATUS.PAID.color },
  createdOrders: { title: 'Created Orders', background: ORDER_STATUS.CREATED.color },
  refundOrders: { title: 'Refund Orders', background: ORDER_STATUS.REFUND.color },
  failOrders: { title: 'Fail Orders', background: ORDER_STATUS.FAIL.color },
  cancelOrders: { title: 'Cancel Orders', background: ORDER_STATUS.CANCELED.color },
};

const Home = () => {
  // @ts-ignore
  const { data, isLoading } = useMetric();

  const renderItem = useCallback((metric: Metric) => (
    <MetricItem
      background={metricMap[metric.name].background}
      title={metricMap[metric.name].title}
      loading={isLoading}
      value={metric.value}
    />
  ), []);

  return (
    <Row gutter={[24, 24]} className={styles.items}>
      { data?.map(renderItem) }
    </Row>
  );
};

export default Home;
