import { Button, Form, Input, Select, Switch, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useCallback, useMemo, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

import ValueForm from '../../Product/Create/components/ValueForm';
import styles from '../../Product/Create/components/CreateProduct/index.module.scss';
import TranslateFormWrapper from '../TranslationForm';

export interface ProductFormProps {
  onFinish: (form: any, file: string) => void;
  categories: Category[];
  product?: Product.Item;
  type: string;
  formRef?: any;
}
export const ProductForm = ({ categories, onFinish, formRef, product, type }: ProductFormProps) => {
  const fileList = product ? [{
    uid: '-1',
    name: 'image.png',
    status: 'done',
    url: product.image,
  }] : [];
  const [selectedCategory, setSelectedCategory] = useState<Category>(product?.category!);
  const [file, setFile] = useState('');

  const handleSetFile = useCallback((e: any) => {
    setFile(e.file);
  }, []);

  const handlerSelectCategory = useCallback((categoryName: string) => {
    const category = categories.find((c) => c.name === categoryName);
    if (category) setSelectedCategory(category);
  }, [categories]);

  const buttonTitle = useMemo(() => (
    (type === 'edit' ? 'Update product' : 'Create product')
  ), [type]);

  return (
    <Form onFinish={(form) => onFinish(form, file)} form={formRef}>
      <Form.Item
        label='Category'
        name='category'
      >
        <Select
          className={styles.categories}
          placeholder='Categories'
          options={categories && categories.map((c) => ({ value: c.name }))}
          onChange={handlerSelectCategory}
        />
      </Form.Item>
      {selectedCategory && <ValueForm category={selectedCategory} />}
      <Form.Item
        label='Product name'
        name='name'
      >
        <Input placeholder='Product name' />
      </Form.Item>
      <Form.Item name='visible' label='All values visible' valuePropName='checked'>
        <Switch />
      </Form.Item>
      <Form.Item
        label='Original name'
        name='originalName'
      >
        <Input placeholder='originalName' />
      </Form.Item>
      <Form.Item
        label='description'
        name='description'
      >
        <Input placeholder='description' />
      </Form.Item>
      <Form.Item
        label='How to use'
        name='howToUse'
      >
        <Input placeholder='How to use' />
      </Form.Item>
      <Upload
        name='file'
        onChange={handleSetFile}
        defaultFileList={fileList as UploadFile<any>[]}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      <TranslateFormWrapper
        field={{ name: 'root', key: 'root' }}
        items={[{
          name: 'description',
          item: <Input placeholder='description translation' />,
        }]}
      />
      <Form.Item>
        <Button className={styles.createButton} type='primary' htmlType='submit'>
          {buttonTitle}
        </Button>
      </Form.Item>
    </Form>
  );
};
